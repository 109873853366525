import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { ICountry } from "../types";

export const getCountriesList = () => {
  return new Promise<ICountry[]>(async (resolve, reject) => {
    try {
      const colRef = collection(firestore, 'countries-list');
      const dataQuery = query(colRef, where('isShow', '==', true));
      const documents = await getDocs(dataQuery);
      const data = documents.docs.map(doc => doc.data());
      return resolve(data as ICountry[]);
    } catch (err) {
      return reject(err);
    }
  })
}