import { RouteProps, Navigate } from "react-router-dom";

// Components
import { Home } from "../pages/home/home";
import { AboutUs } from "../pages/about-us/about-us";
import { ContactUs } from "../pages/contact-us/contact-us";
import { PrivacyPolicy } from "../pages/privacy-policy/privacy-policy";
import { Advertise } from "../pages/advertise/advertise";
import { TermsOfUse } from "../pages/terms-of-use/terms-of-use";
import { News } from "../pages/news/news";
import { Blogs } from "../pages/blogs/blogs";
import { TravelAdvisory } from "../pages/travel-advisory/travel-advisory";
import { ExploreImmigrationOptions } from "../pages/explore-immigration-options/explore-immigration-options";
import { CountryImmigrationInfo } from "../pages/country-immigration-info/country-immigration-info";
import { BlogDetails } from "../pages/blog-details/blog-details";

export const routes: RouteProps[] = [
  {
    path: "/",
    caseSensitive: true,
    element: <Home />,
  },
  {
    path: "/explore/:type",
    caseSensitive: true,
    element: <ExploreImmigrationOptions />,
  },
  {
    path: "/explore/:type/:country",
    caseSensitive: true,
    element: <CountryImmigrationInfo />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
    caseSensitive: true,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
    caseSensitive: true,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    caseSensitive: true,
  },
  {
    path: "/advertise",
    element: <Advertise />,
    caseSensitive: true,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
    caseSensitive: true,
  },
  {
    path: "/news",
    element: <News />,
    caseSensitive: true,
  },
  {
    path: "/blogs/:blogType",
    element: <Blogs />,
    caseSensitive: true,
  },
  {
    path: "/blog/:blogName",
    element: <BlogDetails />,
    caseSensitive: true,
  },
  {
    path: "/travel-advisory",
    element: <TravelAdvisory />,
    caseSensitive: true,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];
