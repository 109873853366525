import { IBlog } from "../../../types";
import { BlogsTypes } from "../../../types/enums";

export interface IBlogGroups {
  type: BlogsTypes;
  blogs: IBlog[];
}

export function groupBlogs(blogs: IBlog[]) {
  if (!blogs.length) {
    return [];
  }
  const groups: IBlogGroups[] = [];
  blogs.forEach((blog) => {
    const groupIndex = groups.findIndex((item) => item.type === blog.type);
    if (groupIndex === -1) {
      const newGroup: IBlogGroups = {
        type: blog.type,
        blogs: [blog],
      };
      groups.push(newGroup);
    } else {
      groups[groupIndex].blogs.push(blog);
    }
  });

  return groups;
}
