import { useEffect, PropsWithChildren } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export const AppScroll = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navType = useNavigationType();
  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location, navType]);

  return <>{children}</>;
};
