import { useEffect, useState } from "react";
import { Col, Row, Skeleton, Typography } from "antd";

import "./privacy-policy.scss";
import { IPrivacyPolicy } from "../../types";
import { getPrivacyPolicies } from "../../apis/privacy-policy";

const { Text, Paragraph } = Typography;

export const PrivacyPolicy = () => {
  const [isFetching, setIsFetching] = useState(true);

  const [privacyPolicies, setPrivacyPolicies] = useState<IPrivacyPolicy[]>([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getPrivacyPolicies();
      setPrivacyPolicies(data);
    }
    try {
      fetchData();
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  return (
    <Row
      className="privacy-policy-container"
      align={"middle"}
      justify={"center"}
    >
      <Col span={24} className="heading-col">
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            <Text className="font-40 font-bold title my-2">Privacy Policy</Text>
          </Col>
          <Col span={24} className="text-center">
            <Text>Last Updated August 30th, 2023</Text>
          </Col>
        </Row>
      </Col>

      {!!isFetching ||
        (!privacyPolicies.length && (
          <Col
            className="content"
            xxl={21}
            xl={21}
            lg={21}
            md={23}
            sm={23}
            xs={23}
          >
            {[1, 2, 3].map((i) => (
              <Skeleton className="mb-4" key={i} active />
            ))}
          </Col>
        ))}

      {!isFetching && !!privacyPolicies.length && (
        <Col
          className="content"
          xxl={21}
          xl={21}
          lg={21}
          md={23}
          sm={23}
          xs={23}
        >
          <Row gutter={[50, 20]}>
            <Col span={24}>
              <Paragraph className="font-16">
                Thank you for visiting our website. This Privacy Policy outlines
                how we collect, use, and safeguard the personal information you
                provide to us while using our immigration website ("Website").
                We value your privacy and are committed to protecting your
                personal information. Please read this Privacy Policy carefully
                to understand our practices regarding your personal information.
              </Paragraph>
            </Col>

            {privacyPolicies.map(({ heading, sections }, mainI) => {
              const mainIndex = mainI + 1;
              return (
                <Col span={24} key={mainI}>
                  <Row>
                    <Col span={24} className="py-2 privacy-heading-col">
                      <Text className="heading font-bold">
                        {mainIndex}. {heading}
                      </Text>
                    </Col>

                    {sections.map((section, sectionI) => {
                      const sectionIndex = sectionI + 1;
                      return (
                        <Col
                          span={24}
                          key={sectionIndex}
                          className="privacy-section-col mt-2"
                        >
                          <Row gutter={[0, 0]}>
                            {section.title && (
                              <Col span={24} className="py-2">
                                <Text className="title font-bold">
                                  {mainIndex}.{sectionIndex} {section.title}
                                </Text>
                              </Col>
                            )}

                            {!!section.paragraphs?.length &&
                              section.paragraphs.map(
                                (paragraph, paragraphI) => (
                                  <Col
                                    span={24}
                                    key={paragraphI}
                                    className="pb-2"
                                  >
                                    <div
                                      className="font-16"
                                      style={{ lineHeight: "1.6rem" }}
                                      dangerouslySetInnerHTML={{
                                        __html: paragraph,
                                      }}
                                    />
                                  </Col>
                                )
                              )}
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
      )}
    </Row>
  );
};
