import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row, Typography, notification } from "antd";
import { IBlog } from "../../types";
import { blogsData } from "../../data";
import { IRImage } from "../../components/shared/ir-image/ir-image";
import { replaceSpaceToHyphenAndLowerCase } from "../../utils/helpers";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "./blog-details.scss";

const { Text } = Typography;

export const BlogDetails = () => {
  const [blogDetails, setBlogDetails] = useState<IBlog | null>(null);

  const [similarBlogs, setSimilarBlogs] = useState<IBlog[]>([]);

  const { blogName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (!blogName) {
        throw new Error("Blog not found");
      }
      const selectedBlog = blogsData.find(
        (blog) => replaceSpaceToHyphenAndLowerCase(blog.title) === blogName
      );
      if (!selectedBlog) {
        throw new Error("Data not found");
      }
      setBlogDetails(selectedBlog);
      const blogs = blogsData.filter(
        (blog) => blog.type === selectedBlog.type && blog.id !== selectedBlog.id
      );
      setSimilarBlogs(blogs);
    } catch (err: any) {
      console.log("err", err);
      notification.error({
        message: err.message,
        type: "error",
        placement: "top",
      });
      navigate("/", { replace: true });
    }
  }, [blogName]);

  function goBack() {
    navigate(`/blogs/${replaceSpaceToHyphenAndLowerCase(blogDetails?.type)}`);
  }

  if (!blogDetails) {
    return null;
  }

  return (
    <Row
      className="blog-details-container mb-5 mt-10"
      justify={"center"}
      align={"middle"}
    >
      <Col xxl={19} xl={19} lg={19} md={21} sm={21} xs={21}>
        <Row gutter={[0, 10]} className="mb-6">
          <Col span={24} className="mb-4 cursor-pointer">
            <Row gutter={[20, 10]} align={"middle"} onClick={() => goBack()}>
              <Col span={"auto"}>
                <ArrowLeftOutlined className="back-icon" />
              </Col>
              <Col span={"auto"}>
                <Text className="font-18 black-1 hover-primary">
                  See our all blogs for {blogDetails.type}
                </Text>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Button type="primary" danger size="large">
              {blogDetails.type}
            </Button>
          </Col>

          <Col span={24}>
            <Text className="blog-title">{blogDetails.title}</Text>
          </Col>

          <Col span={24} className="mt-2 mb-3">
            <Text className="grey-7">
              <span>{blogDetails.date.toDateString()}</span>
              <hr className="hr-line" />
              <span>{blogDetails.readingMins} Mins</span>
            </Text>
          </Col>

          <Col span={24} className="blog-image">
            <IRImage src={blogDetails.image} />
          </Col>
        </Row>

        <Row gutter={[20, 40]} className="paragraphs">
          {blogDetails.paragraphs.map((paragraph, i) => (
            <Col span={24} key={i}>
              <Row gutter={[0, 10]}>
                {paragraph.title && (
                  <Col span={24}>
                    <Text className="font-22 font-bold black-1 title">
                      {paragraph.title}
                    </Text>
                  </Col>
                )}
                {paragraph.text && (
                  <Col span={24}>
                    <div
                      className="font-17 grey-5 text"
                      dangerouslySetInnerHTML={{ __html: paragraph.text }}
                      style={{ lineHeight: "1.6rem" }}
                    ></div>
                  </Col>
                )}
                {paragraph.image && (
                  <Col span={24} className="my-3 image">
                    <IRImage src={paragraph.image} />
                  </Col>
                )}
                {!!paragraph.points?.length && (
                  <ol className="paragraph-points pl-4">
                    {paragraph.points.map((point, i) => (
                      <li key={i} className="mt-2 font-18 grey-5">
                        {point}
                      </li>
                    ))}
                  </ol>
                )}
              </Row>
            </Col>
          ))}
        </Row>

        {!!blogDetails.list?.length && (
          <Row>
            <Col span={24} className="list-type-col">
              <ul>
                {blogDetails.list.map(
                  (item, itemI) =>
                    item && (
                      <li key={itemI} className="font-18 mb-3">
                        {item}
                      </li>
                    )
                )}
              </ul>
            </Col>
          </Row>
        )}

        {/* Similar Blogs */}
        {!!similarBlogs.length && (
          <Row className="my-10">
            <Col span={24}>
              <Row className="similar-blogs" gutter={[0, 20]}>
                <Col span={24} className="title">
                  <Text className="font-40 black-1">Similar Blogs</Text>
                </Col>

                <Col span={24}>
                  <Row gutter={[30, 0]}>
                    {similarBlogs.map((blog, i) => (
                      <Col
                        xxl={8}
                        xl={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                        key={i}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/blog/${replaceSpaceToHyphenAndLowerCase(
                              blog.title
                            )}`
                          )
                        }
                      >
                        <Row gutter={[0, 10]}>
                          <Col span={24} className="similar-blog-image">
                            <IRImage src={blog.image} />
                          </Col>

                          <Col span={24} className="my-2">
                            <Text className="grey-7">
                              <span>{blogDetails.date.toDateString()}</span>
                              <hr className="hr-line" />
                              <span>{blogDetails.readingMins} Mins</span>
                            </Text>
                          </Col>

                          <Col className="hover-primary">
                            <Text className="font-22 black-1 font-bold">
                              {blog.title}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
