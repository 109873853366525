import { IAdvisoryData } from "../types";

export const fetchTravelAdvisoryData = (): Promise<IAdvisoryData> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch("https://www.travel-advisory.info/api");
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const data = await response.json();
      resolve(data.data);
    } catch (err) {
      console.log("err", err);
      return reject(err);
    }
  });
};
