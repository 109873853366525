import { Button, Col, Row } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import "./app-channels.scss";

export const AppChannels = () => {
  const socialAccounts = [
    {
      icon: <YoutubeOutlined />,
      link: "https://youtube.com/@Immigrationroutes",
    },
    {
      icon: <FacebookOutlined />,
      link: "https://www.facebook.com/profile.php?id=100095566231736&mibextid=ZbWKwL",
    },
    {
      icon: <InstagramOutlined />,
      link: "https://www.instagram.com/immigrationroutes/",
    },
    {
      icon: <WhatsAppOutlined />,
      link: "https://www.whatsapp.com/channel/0029Va9Z6zkDzgTEafjqom1S",
    },
  ];
  return (
    <Row gutter={[15, 0]} className="app-channels">
      {socialAccounts.map((account) => (
        <Col key={account.link}>
          <Button
            icon={account.icon}
            shape="circle"
            href={account.link}
            target="_blank"
            className="link-btn"
          />
        </Col>
      ))}
    </Row>
  );
};
