import { Col, Row, Skeleton, Typography } from "antd";
import { ITermsOfUse } from "../../types";
import { useEffect, useState } from "react";
import { getTermsOfUse } from "../../apis/terms-of-use";

import "./terms-of-use.scss";

const { Text } = Typography;

export const TermsOfUse = () => {
  const [isFetching, setIsFetching] = useState(true);

  const [termsOfUse, setTermsOfUse] = useState<ITermsOfUse[]>([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getTermsOfUse();
      setTermsOfUse(data);
    }
    try {
      fetchData();
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  return (
    <Row className="terms-of-use-container" align={"middle"} justify={"center"}>
      {/* Top content */}
      <Col span={24} className="heading-col">
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            <Text className="font-40 font-bold title my-2">Terms of Use</Text>
          </Col>
          <Col span={24} className="text-center">
            <Text>Last Updated September 16th, 2023</Text>
          </Col>
        </Row>
      </Col>

      {!!isFetching ||
        (!termsOfUse.length && (
          <Col
            className="content px-2"
            xxl={21}
            xl={21}
            lg={21}
            md={23}
            sm={23}
            xs={23}
          >
            {[1, 2, 3].map((i) => (
              <Skeleton className="mb-4" key={i} active />
            ))}
          </Col>
        ))}

      {/* Bottom content */}
      {!isFetching && !!termsOfUse.length && (
        <Col
          className="content"
          xxl={21}
          xl={21}
          lg={21}
          md={22}
          sm={22}
          xs={22}
        >
          <Row gutter={[40, 20]}>
            {termsOfUse.map(({ title, paragraphs }, i) => (
              <Col span={24} key={i} className="term-of-use-section px-0 pb-5">
                <Row gutter={[20, 10]}>
                  {/* Title */}
                  {title && (
                    <Col span={24}>
                      <Text className="font-26 section-heading">{title}</Text>
                    </Col>
                  )}

                  {/* Paragraphs */}
                  {!!paragraphs?.length &&
                    paragraphs.map((paragraph, paragraphI) => (
                      <Col span={24} key={paragraphI}>
                        <div
                          className="font-16"
                          style={{ lineHeight: "1.6rem" }}
                          dangerouslySetInnerHTML={{ __html: paragraph }}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  );
};
