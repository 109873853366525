import { useState } from "react";
import { Col, Drawer, List, Row } from "antd";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { useIsMobile } from "../../helpers/hooks/use-is-mobile/use-is-mobile";
import { BlogsTypes } from "../../types/enums";
import { IRImage } from "../shared/ir-image/ir-image";

import "./app-header.scss";
// import { firebaseApp } from "../../firebase/firebase";
// import { AnalyticsEvents } from "../../firebase/enums";

export const AppHeader = () => {
  const isMobile = useIsMobile();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const mainHeaderLinks = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "News",
      path: "/news",
    },
    {
      name: "Read our blogs",
      path: `/blogs/${BlogsTypes.DestinationGuides}`,
    },
    {
      name: "Travel Advisory",
      path: "/travel-advisory",
    },
  ];
  const pagesLinks = [
    {
      name: "Contact us",
      path: "/contact-us",
    },
    {
      name: "About us",
      path: "/about-us",
    },
    {
      name: "Privacy policy",
      path: "/privacy-policy",
    },
    {
      name: "Advertise",
      path: "/advertise",
    },
    {
      name: "Terms of Use",
      path: "/terms-of-use",
    },
  ];
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // const pageClickEvent = (page: string) => {
  //   const eventData = {
  //     page,
  //     time: new Date().toDateString(),
  //   };
  //   firebaseApp.logFirebaseEvent(AnalyticsEvents.PageClick, eventData);
  // };

  return (
    <Row className="header py-5">
      <Col span={24}>
        <Row align={"middle"} justify={"space-between"}>
          <Col className="bg-white">
            <NavLink to="/" className="logo">
              <IRImage src={Logo} />
              <span className="px-2 font-20 font-bold">Immigration Routes</span>
            </NavLink>
          </Col>
          <Col className="page-links-col">
            <Row gutter={[20, 0]}>
              {!isMobile && (
                <Col>
                  <Row gutter={[20, 0]} className="page-links-row">
                    {/* {mainHeaderLinks.map((link) => (
                      <Col key={link.name}>
                        <NavLink
                          to={link.path}
                          key={link.path}
                          className="font-14"
                          onClick={() => pageClickEvent(link.name)}
                        >
                          {link.name}
                        </NavLink>
                      </Col>
                    ))} */}
                    * We do not provide legal advice
                  </Row>
                </Col>
              )}
              <Col>
                <MenuOutlined className="menu-btn" onClick={toggleDrawer} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Drawer
        onClose={toggleDrawer}
        open={isDrawerOpen}
        closeIcon={false}
        rootClassName="side-drawer"
      >
        <Row className="pt-5 px-7">
          <Col span={24} onClick={toggleDrawer}>
            <CloseOutlined className="close-icon cursor-pointer" />
          </Col>

          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={
                isMobile ? [...mainHeaderLinks, ...pagesLinks] : pagesLinks
              }
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <NavLink
                    className="page-link"
                    to={item.path}
                    onClick={toggleDrawer}
                  >
                    {item.name}
                  </NavLink>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Drawer>
    </Row>
  );
};
