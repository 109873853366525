import { Layout } from "antd";
import { PropsWithChildren } from "react";
import { AppHeader } from "../app-header/app-header";
import "./app-layout.scss";
import { AppFooter } from "../app-footer/app-footer";

const { Content } = Layout;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppLayoutProps {}

export const IRLayout = ({ children }: PropsWithChildren<AppLayoutProps>) => {
  return (
    <Layout rootClassName="app-layout">
      <AppHeader />
      <Content className="app-content">{children}</Content>
      <AppFooter />
    </Layout>
  );
};
