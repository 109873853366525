import { useEffect, useState } from "react";
import { Col, Row, Skeleton, Typography } from "antd";
import { IRImage } from "../../components/shared/ir-image/ir-image";
import { IAboutUsInfo } from "../../types";
import { getAboutUs } from "../../apis/about-us";

import "./about-us.scss";

const { Text, Title } = Typography;

export const AboutUs = () => {
  const [isFetching, setIsFetching] = useState(true);

  const [aboutUs, setAboutUs] = useState<IAboutUsInfo>();

  useEffect(() => {
    async function fetchData() {
      const data = await getAboutUs();
      setAboutUs(data);
    }
    try {
      fetchData();
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  return (
    <Row
      className="about-us-container"
      justify={"space-evenly"}
      gutter={[
        { xxl: 100, xl: 100, lg: 100, md: 50, sm: 0, xs: 0 },
        { xxl: 50, xl: 50, lg: 50, md: 50, sm: 0, xs: 0 },
      ]}
      align={"middle"}
    >
      {/* Top content */}
      <Col span={24} className="heading-col mb-5">
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            <Text className="font-40 font-bold title my-2">About Us</Text>
          </Col>
          <Col span={24} className="content text-center">
            <Text>
              Discover our mission, values, and team, and learn about our
              commitment to providing reliable and credible news
            </Text>
          </Col>
        </Row>
      </Col>

      {!!isFetching ||
        (!aboutUs && (
          <Col
            className="content"
            xxl={21}
            xl={21}
            lg={21}
            md={23}
            sm={23}
            xs={23}
          >
            {[1, 2, 3].map((i) => (
              <Skeleton className="mb-4" key={i} active />
            ))}
          </Col>
        ))}

      {/* Bottom content */}
      {!isFetching && aboutUs && (
        <Col xxl={21} xl={21} lg={21} md={22} sm={22} xs={22}>
          <Row gutter={[0, 20]} align={"middle"} justify={"space-between"}>
            <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
              <IRImage src={aboutUs.starting.image} className="display-image" />
            </Col>

            <Col
              xxl={17}
              xl={17}
              lg={17}
              md={17}
              sm={24}
              xs={24}
              className="text-center-mobile"
            >
              <Title>{aboutUs.starting.title}</Title>

              <Text className="font-18">{aboutUs.starting.description}</Text>
            </Col>
          </Row>

          <Row className="py-10 my-5 about-us-content" gutter={[50, 50]}>
            {aboutUs.contents.map((content, i) => (
              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={24}
                sm={24}
                xs={24}
                key={i}
                className="text-center-mobile"
              >
                <Title className="mt-0">{content.title}</Title>
                <Text className="font-18">{content.description}</Text>
              </Col>
            ))}
          </Row>

          <Row
            gutter={[0, 20]}
            justify={"space-between"}
            align={"middle"}
            className="commitment-row"
          >
            <Col
              xxl={14}
              xl={14}
              lg={14}
              md={14}
              sm={24}
              xs={24}
              className="text-center-mobile"
            >
              <Title>{aboutUs.commitment.title}</Title>

              <Text className="font-18">{aboutUs.commitment.description}</Text>
            </Col>
            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
              <IRImage
                src={aboutUs.commitment.image}
                className="display-image"
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
