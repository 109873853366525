import { useEffect, useState } from "react";
import { Button, ConfigProvider, Modal } from "antd";
import "./App.scss";
import { IRLayout } from "./components/app-layout/app-layout";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { AppScroll } from "./components/app-scroll/app-scroll";

function App() {
  const [isShowDisclaimerModal, setIsShowDisclaimerModal] = useState(false);

  useEffect(() => {
    if (
      !localStorage.getItem("isDisclaimerAccepted") ||
      localStorage.getItem("isDisclaimerAccepted") === "false"
    ) {
      setIsShowDisclaimerModal(true);
    }
  }, []);

  const onAccept = () => {
    localStorage.setItem("isDisclaimerAccepted", "true");
    setIsShowDisclaimerModal(false);
  };

  const onDeny = () => {
    // closing the website on deny
    setIsShowDisclaimerModal(false);
  };
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: { fontFamily: "Roboto, sans-serif" },
      }}
    >
      <IRLayout>
        <AppScroll>
          <Routes>
            {routes.map((props) => (
              <Route {...props} key={props.path} />
            ))}
          </Routes>

          {/* Disclaimer Modal */}
          <Modal
            title="Acknowledgment"
            open={isShowDisclaimerModal}
            className="disclaimer-modal"
            footer={[
              <Button key="submit" onClick={onAccept} type="primary">
                I Accepted
              </Button>,
            ]}
            closable={false}
          >
            <p className="font-16">
              By using Immigration Routes, you acknowledge and agree to our
              Terms and Conditions and all Policies. Please review these
              information carefully. Your continued use of this site constitutes
              your acceptance of all terms and conditions.
            </p>
          </Modal>
        </AppScroll>
      </IRLayout>
    </ConfigProvider>
  );
}

export default App;
