import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Select,
  Skeleton,
  Typography,
  notification,
} from "antd";
import { IRImage } from "../../components/shared/ir-image/ir-image";
import { useIsMobile } from "../../helpers/hooks/use-is-mobile/use-is-mobile";
import { ICountry, IVisaInfo, IVisaTypeInfo } from "../../types";
import { getVisaTypesInfo } from "../../apis/visa-types-info";

import "./explore-immigration-options.scss";
import { getCountriesList } from "../../apis/countries-list";

const { Title, Text } = Typography;
const { Input: LoadingInput } = Skeleton;

export const ExploreImmigrationOptions = () => {
  const { type: immigrationType } = useParams();

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const [isFetchingVisaInfo, setIsFetchingVisaInfo] = useState(false);
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);

  const [selectedTypeInfo, setSelectedTypeInfo] = useState<IVisaInfo | null>(
    null
  );

  const [countries, setCounties] = useState<ICountry[]>([]);

  const [selectedCountry, setSelectedCountry] = useState("");

  const params = useParams();

  useEffect(() => {
    getVisaTypeInfo();
    getCountries();
  }, []);

  const getCountries = async () => {
    try {
      setIsFetchingCountries(true);
      const data: ICountry[] = await getCountriesList();
      setCounties(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetchingCountries(false);
    }
  };

  const getVisaTypeInfo = async () => {
    try {
      setIsFetchingVisaInfo(true);
      const data: IVisaTypeInfo = await getVisaTypesInfo();
      if (!immigrationType || !data[immigrationType]) {
        notification.error({
          message: "Invalid option",
          type: "error",
          placement: "top",
        });
        navigate("/", { replace: true });
        return;
      }
      setSelectedTypeInfo(data[immigrationType]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetchingVisaInfo(false);
    }
  };

  return (
    <Row className="explore-immigration-options-container">
      {/* Placeholder cards */}
      {!!isFetchingVisaInfo && (
        <Col span={24} className="mt-5">
          <Row justify={"center"} gutter={[30, 30]}>
            <Col span={24} className="text-center">
              <LoadingInput
                block
                active
                style={{ height: 35, width: "10%", borderRadius: 8 }}
              />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={16}
              sm={23}
              xs={23}
              className="text-center"
            >
              <LoadingInput
                active
                block
                style={{ height: isMobile ? 200 : 320, borderRadius: 8 }}
              />
            </Col>

            <Col xxl={21} xl={21} lg={21} md={22} sm={23} xs={23}>
              {[1, 2, 3].map((i) => (
                <LoadingInput
                  key={i}
                  active
                  block
                  style={{ borderRadius: 8 }}
                  className="mb-3"
                />
              ))}
            </Col>
          </Row>
        </Col>
      )}

      {/* Visa type data */}
      {!!selectedTypeInfo && !isFetchingVisaInfo && (
        <Col span={24}>
          <Row justify={"center"}>
            <Col span={24} className="text-center">
              <Title className="title">{selectedTypeInfo.title}</Title>
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={16}
              sm={23}
              xs={23}
              className="text-center"
            >
              <IRImage
                src={selectedTypeInfo.image}
                wrapperClassName="explore-image"
                height={isMobile ? 200 : 320}
              />
            </Col>
          </Row>

          <Row
            className="my-4 mx-4 content-row text-center"
            align={"middle"}
            justify={"center"}
          >
            <Col xxl={21} xl={21} lg={21} md={22} sm={23} xs={23}>
              <Row gutter={[0, 40]}>
                <Col span={24}>
                  <span
                    className="font-18"
                    dangerouslySetInnerHTML={{ __html: selectedTypeInfo.text }}
                    style={{ lineHeight: "1.6rem" }}
                  ></span>
                </Col>

                <Col span={24} className="mb-10">
                  <Row gutter={[0, 10]}>
                    <Col span={24}>
                      <Text className="font-24">
                        Please select your destination
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Select
                        className="country-select"
                        style={{ width: 200 }}
                        onChange={setSelectedCountry}
                        value={selectedCountry}
                        options={countries}
                        size="large"
                        disabled={isFetchingCountries}
                        filterSort={(a, b) => a.label.localeCompare(b.label)}
                        showSearch
                        placeholder="Select country"
                        filterOption={(input, option) => {
                          if (!option) {
                            return true;
                          }
                          return (
                            option?.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option?.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      />
                    </Col>

                    <Col span={24}>
                      <Button
                        disabled={!selectedCountry}
                        size={"large"}
                        onClick={() =>
                          navigate(`/explore/${params.type}/${selectedCountry}`)
                        }
                        loading={isFetchingCountries}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
