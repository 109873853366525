import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from 'firebase/analytics'
import { AnalyticsEvents } from "./enums";
import { EventData } from "./types";
import { Firestore, getFirestore } from "firebase/firestore";

let firebaseApp: FirebaseApp;
let firestore: Firestore;

class FirebaseApp {
  app: any;
  analytics: any;
  configs: FirebaseOptions = {};
  constructor(configs: FirebaseOptions) {
    this.configs = configs;
  }

  public initApp() {
    this.app = initializeApp(this.configs);
    this.analytics = getAnalytics(this.app);
    return this.app;
  }
  
  public logFirebaseEvent(eventName: AnalyticsEvents, payload: EventData) {
    logEvent(this.analytics, eventName, payload)
  }
  get getApp() {
    return this.app;
  }
}

function init(configs: FirebaseOptions) {
  firebaseApp = new FirebaseApp(configs);
  const app = firebaseApp.initApp();
  firestore = getFirestore(app);
}

export { init, firebaseApp, firestore }