import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Typography } from "antd";
import { CalendarOutlined, RightOutlined } from "@ant-design/icons";
import { IRImage } from "../../shared/ir-image/ir-image";
import { blogsData } from "../../../data";
import { IBlogGroups, groupBlogs } from "./helpers";
import { replaceSpaceToHyphenAndLowerCase } from "../../../utils/helpers";

import "./blogs.scss";

const { Text } = Typography;

export const Blogs = () => {
  const [blogGroups, setBlogGroups] = useState<IBlogGroups[]>([]);

  const navigate = useNavigate();

  const onBlogClick = (blogName: string) => {
    navigate(`/blog/${replaceSpaceToHyphenAndLowerCase(blogName)}`);
  };

  useEffect(() => {
    const groups = groupBlogs(blogsData);
    // showing only 3 blogs
    setBlogGroups(groups.splice(0, 3));
  }, []);

  return (
    <Row className="blogs-and-articles mb-6" gutter={[30, 0]}>
      {blogGroups.map((item, i) => {
        const { type, blogs } = item;
        return (
          <Col xl={8} lg={8} md={12} sm={12} xs={24} key={i}>
            {/* Blog Type */}
            <Row>
              <Col span={24} className="py-4">
                <Text className="font-24 blog-type font-bold black-1">
                  {type}
                </Text>
              </Col>
            </Row>

            {blogs?.map((blog, blogIndex) => (
              <Row key={blogIndex}>
                {
                  // for first index only
                  blogIndex === 0 && (
                    <Col
                      span={24}
                      className="main-blog hover-primary"
                      onClick={() => onBlogClick(blog.title)}
                    >
                      <Row>
                        <Col span={24}>
                          <IRImage src={blog.image} className="image" />
                        </Col>

                        <Col span={24} className="py-2">
                          <CalendarOutlined className="grey-5" />
                          <Text className="pl-2 font-16 grey-5">
                            {blog.date.toLocaleDateString()}
                          </Text>
                        </Col>

                        <Col span={24}>
                          <Text className="font-18 title black-1 font-bold">
                            {blog.title}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  )
                }

                {blogIndex > 0 && (
                  <Col
                    span={24}
                    key={blogIndex}
                    className="other-blogs mt-3 hover-primary"
                    onClick={() => onBlogClick(blog.title)}
                  >
                    <Row
                      justify={"space-between"}
                      align={"middle"}
                      gutter={[20, 30]}
                    >
                      <Col xxl={16} xl={16} lg={16} md={14} sm={14} xs={14}>
                        <Text className="title font-16 black-1 font-bold">
                          {blog.title}
                        </Text>
                      </Col>
                      <Col xxl={8} xl={8} lg={8} md={10} sm={9} xs={9}>
                        <IRImage src={blog.image} className="image" />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            ))}

            {blogs.length > 1 && (
              <Row className="mt-6 hover-primary">
                <Col
                  span={24}
                  className="text-right"
                  onClick={() =>
                    navigate(`/blogs/${replaceSpaceToHyphenAndLowerCase(type)}`)
                  }
                >
                  <Text className="go-btn-text font-16 title font-bold">
                    Go to {type}
                  </Text>
                  <Button
                    icon={<RightOutlined />}
                    shape="circle"
                    className="go-btn px-1"
                    type="primary"
                  />
                </Col>
              </Row>
            )}
          </Col>
        );
      })}
    </Row>
  );
};
