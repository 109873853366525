import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { IAboutUsInfo } from "../types";

export const getAboutUs = () => {
  return new Promise<IAboutUsInfo>(async (resolve, reject) => {
    try {
      const collectionCol = collection(firestore, "about-us");
      const documents = await getDocs(collectionCol);
      const data = documents.docs.map((doc) => doc.data());
      return resolve(data[0] as IAboutUsInfo);
    } catch (err) {
      return reject(err);
    }
  });
};
