import { useState } from "react";
import { Image, ImageProps, Skeleton } from "antd";

import "./ir-image.scss";

// Images
import ErrorImage from "../../../assets/error-image.png";

const { Image: LoadingImage } = Skeleton;

export const IRImage = ({ width, height, ...res }: ImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <>
      <Image
        preview={false}
        placeholder
        onLoad={() => setIsImageLoaded(true)}
        fallback={ErrorImage}
        loading="eager"
        {...res}
      />
      {!isImageLoaded && (
        <LoadingImage
          rootClassName="loading-image"
          active
          style={{ minHeight: height, width: width || "100%" }}
        />
      )}
    </>
  );
};
