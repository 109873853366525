import { EnvironmentsTypes } from '../types/enums';
import { init as initFirebaseApp } from '../firebase/firebase';
import { FirebaseOptions } from 'firebase/app';

type InitFunction = () => void;

interface ILoaders {
  [key: string]: InitFunction[];
}


const initFirebase = () => {
  // Your web app's Firebase configuration
  const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "immigration-routes.firebaseapp.com",
    databaseURL: "https://immigration-routes.firebaseio.com",
    projectId: "immigration-routes",
    storageBucket: "immigration-routes.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  initFirebaseApp(firebaseConfig);
}


export const initializeLibraries = () => {
  let devInitializers = [initFirebase];
  let stagingInitializers = [initFirebase];
  let prodInitializers = [initFirebase];

  // load orders should be maintained
  const loaders: ILoaders = {
    [EnvironmentsTypes.Development]: devInitializers,
    [EnvironmentsTypes.Staging]: stagingInitializers,
    [EnvironmentsTypes.Production]: prodInitializers,
  };

  const appEnv: EnvironmentsTypes = process.env.REACT_APP_IR_ENV as EnvironmentsTypes;

  if (!loaders[appEnv]) {
    return;
  }
  loaders[appEnv].forEach((initiateLibrary: InitFunction) => initiateLibrary());
};