import {
  Avatar,
  Button,
  Card,
  Col,
  Image,
  Input,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { fetchTravelAdvisoryData } from "../../apis/travel-advisory";
import { useEffect, useState } from "react";
import { IAdvisoryData } from "../../types";
import { LinkOutlined, SearchOutlined } from "@ant-design/icons";

// Images
import Empty from "../../assets/empty-blogs.svg";

import "./travel-advisory.scss";

const { Text } = Typography;

const { Search } = Input;

export const TravelAdvisory = () => {
  const [travelAdvisoryData, setTravelAdvisoryData] = useState<IAdvisoryData>(
    {}
  );
  const [clonedTravelAdvisoryData, setClonedTravelAdvisoryData] =
    useState<IAdvisoryData>({});
  const [isFetching, setIsFetching] = useState(true);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchTravelAdvisoryData();
        setTravelAdvisoryData(data);
        setClonedTravelAdvisoryData(data);
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsFetching(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let timeoutId: any;
    if (searchValue && clonedTravelAdvisoryData) {
      timeoutId = setTimeout(() => {
        const filterCountries: any = Object.entries(
          clonedTravelAdvisoryData
        ).reduce((sum, [name, info]) => {
          if (
            info.name
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            name.toLocaleLowerCase().startsWith(searchValue.toLocaleLowerCase())
          ) {
            return { [name]: info, ...sum };
          }
          return sum;
        }, {});
        setTravelAdvisoryData(filterCountries);
      }, 500);
    } else {
      setTravelAdvisoryData(clonedTravelAdvisoryData);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [searchValue, 500]);

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  return (
    <Row className="travel-advisory-container" justify={"center"}>
      {/* Top content */}
      <Col span={24} className="heading-col mb-5">
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            <Text className="font-40 font-bold title my-2">
              Travel Advisory
            </Text>
          </Col>
        </Row>
      </Col>

      <Col xxl={20} xl={20} lg={20} md={22} sm={22} xs={22}>
        <Row gutter={[20, 20]}>
          <Col span={24} className="my-4">
            <Search
              size="large"
              placeholder="Search country"
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearchChange(e)}
              disabled={isFetching}
              loading={isFetching}
            />
          </Col>

          {isFetching &&
            Array(21)
              .fill(1)
              .map((_, index) => (
                <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={24} key={index}>
                  <Card loading>
                    <Skeleton />
                  </Card>
                </Col>
              ))}

          {!isFetching &&
            travelAdvisoryData &&
            Object.values(travelAdvisoryData).map((value) => (
              <Col
                xxl={8}
                xl={8}
                lg={8}
                md={12}
                sm={12}
                xs={24}
                key={value.iso_alpha2}
              >
                <Card className="full-width full-height advisory-card">
                  <Card.Meta
                    avatar={
                      <Avatar
                        src={`https://flagpedia.net/data/flags/h80/${value.iso_alpha2.toLowerCase()}.png`}
                        size={"large"}
                      />
                    }
                    title={value.name}
                    description={
                      <Row>
                        <Col span={24}>
                          <Text className="grey-5">
                            {value.advisory.message}
                          </Text>
                        </Col>

                        <Col span={24}>
                          <Text>
                            <span>Active source: </span>
                            {value.advisory.sources_active}
                          </Text>
                        </Col>

                        <Col span={24}>
                          <Button
                            type="link"
                            icon={<LinkOutlined />}
                            iconPosition="end"
                            className="pl-0 text-underline"
                            href={value.advisory.source}
                            target="_blank"
                          >
                            View source website
                          </Button>
                        </Col>

                        <Col span={24} className="top-info">
                          <div className="last-updated text-right black-1 font-bold">
                            <Text>
                              <span>Last updated: </span>
                              {new Date(value.advisory.updated).toDateString()}
                            </Text>
                          </div>
                          <div className="text-right risk font-bold">
                            <Text>
                              Travel risk level:
                              <span> {value.advisory.score}</span>
                            </Text>
                          </div>
                        </Col>
                      </Row>
                    }
                  />
                </Card>
              </Col>
            ))}

          {!isFetching && !Object.values(travelAdvisoryData).length && (
            <Col span={24} className="text-center">
              <Row justify={"center"}>
                <Col span={24}>
                  <Image src={Empty} preview={false} width={230} />
                </Col>
                <Col span={"24"}>
                  <Text className="font-22 black-1">No country found!</Text>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
