import { Col, Row, Typography, notification, Button, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IRImage } from "../../components/shared/ir-image/ir-image";
import { ICountryImmigrationAndVisaInfo, IVisaTypeWithInfo } from "../../types";
import { getCountriesVisaImmigrationInfo } from "../../apis/country-visa-immigration-info";
import { useIsMobile } from "../../helpers/hooks/use-is-mobile/use-is-mobile";
import { ReloadOutlined } from "@ant-design/icons";

import "./country-immigration-info.scss";

const { Input: LoadingInput } = Skeleton;
const { Title, Text } = Typography;

export const CountryImmigrationInfo = () => {
  const params = useParams();
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const [isFetchingData, setIsFetchingData] = useState(true);

  const [visaTypeWithInfo, setVisaTypeWithInfo] = useState<IVisaTypeWithInfo>();
  const [countriesImmigrationTypeInfo, setCountriesImmigrationTypeInfo] =
    useState<ICountryImmigrationAndVisaInfo[]>([]);

  const getImmigrationAndVisaInfo = async () => {
    try {
      setIsFetchingData(true);
      const data = await getCountriesVisaImmigrationInfo();
      setCountriesImmigrationTypeInfo(data);
    } catch (err: any) {
      notification.error({
        message: err.message,
        type: "error",
        placement: "top",
      });
      navigate("/", { replace: true });
    } finally {
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    getImmigrationAndVisaInfo();
  }, []);

  useEffect(() => {
    if (countriesImmigrationTypeInfo.length) {
      try {
        if (!params.type || !params.country) {
          throw new Error("Immigration type or country not found");
        }
        const visaInfo = countriesImmigrationTypeInfo
          .find((item) => item.country === params.country)
          ?.visaTypeWithInfo.find((item) => item.type === params.type);

        if (!visaInfo) {
          throw new Error("No Data found");
        }
        setVisaTypeWithInfo(visaInfo);
      } catch (err: any) {
        notification.error({
          message: err.message,
          type: "error",
          placement: "top",
        });
        navigate("/", { replace: true });
      }
    }
  }, [countriesImmigrationTypeInfo.length]);

  return (
    <Row className="country-immigration-info-container mb-10">
      {/* Placeholder cards */}
      {isFetchingData && (
        <Col span={24} className="mt-5">
          <Row justify={"center"} gutter={[0, 30]} className="mx-4">
            <Col span={24} className="text-center">
              <LoadingInput
                block
                active
                style={{
                  height: 30,
                  width: isMobile ? "60%" : "20%",
                  borderRadius: 8,
                }}
              />
            </Col>

            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={16}
              sm={23}
              xs={23}
              className="text-center"
            >
              <LoadingInput
                active
                block
                style={{ height: isMobile ? 200 : 320, borderRadius: 12 }}
              />
            </Col>

            <Col xxl={21} xl={21} lg={21} md={22} sm={23} xs={23}>
              {[1, 2, 3].map((i) => (
                <LoadingInput
                  key={i}
                  active
                  block
                  style={{ borderRadius: 8, height: 25 }}
                  className="mb-3"
                />
              ))}
            </Col>

            <Col xxl={21} xl={21} lg={21} md={22} sm={23} xs={23}>
              {[1, 2, 3].map((i) => (
                <LoadingInput
                  key={i}
                  active
                  block
                  style={{ borderRadius: 8, height: 25 }}
                  className="mb-3"
                />
              ))}
            </Col>

            <Col xxl={21} xl={21} lg={21} md={22} sm={23} xs={23}>
              {[1, 2, 3].map((i) => (
                <LoadingInput
                  key={i}
                  active
                  block
                  style={{ borderRadius: 8, height: 25 }}
                  className="mb-3"
                />
              ))}
            </Col>
          </Row>
        </Col>
      )}

      {!isFetchingData &&
        !!countriesImmigrationTypeInfo.length &&
        visaTypeWithInfo && (
          <Col span={24}>
            <Row className="pb-8" gutter={[0, 20]}>
              {/* Top Page Title */}
              <Col span={24} className="text-center">
                <Title className="title">{visaTypeWithInfo.title}</Title>
              </Col>

              {/* Immigration type cover image */}
              <Col span={24} className="text-center">
                <IRImage
                  src={visaTypeWithInfo.coverImage}
                  wrapperClassName="explore-image"
                  height={400}
                  width={"50%"}
                />
              </Col>
            </Row>

            <Row
              className="my-4 mx-4 content-row"
              align={"middle"}
              justify={"center"}
            >
              <Col xxl={21} xl={21} lg={21} md={21} sm={22} xs={22}>
                <Row gutter={[0, 40]}>
                  {visaTypeWithInfo.info.map((info, infoI) => (
                    <Col span={24} key={infoI}>
                      <Row gutter={[20, 20]} align={"top"}>
                        {/* Title */}
                        {info.title && (
                          <Col span={24}>
                            <Text className="font-24 font-bold">
                              {info.title}
                            </Text>
                          </Col>
                        )}

                        {/* Image */}
                        {info.image && (
                          <Col span={24}>
                            <IRImage
                              src={info.image}
                              wrapperClassName="info-image"
                            />
                          </Col>
                        )}

                        {/* Description */}
                        {info.description && (
                          <Col span={24}>
                            <div
                              className="font-18"
                              dangerouslySetInnerHTML={{
                                __html: info.description,
                              }}
                            ></div>
                          </Col>
                        )}

                        {/* Paragraphs */}
                        {!!info.paragraphs?.length && (
                          <Col span={24}>
                            <Row gutter={[10, 25]}>
                              {info.paragraphs.map(
                                (paragraph, paragraphI) =>
                                  paragraph && (
                                    <Col span={24} key={paragraphI}>
                                      <div
                                        className="font-18"
                                        dangerouslySetInnerHTML={{
                                          __html: paragraph,
                                        }}
                                      ></div>
                                    </Col>
                                  )
                              )}
                            </Row>
                          </Col>
                        )}

                        {/* List type content */}
                        {!!info.list?.length && (
                          <Col span={24} className="list-type-col">
                            <ul>
                              {info.list.map(
                                (item, itemI) =>
                                  item && (
                                    <li key={itemI} className="font-18 mb-3">
                                      {item}
                                    </li>
                                  )
                              )}
                            </ul>
                          </Col>
                        )}

                        {/* Links */}
                        {!!info.links?.length && (
                          <Col span={24} className="link-type-col pl-6">
                            {info.links.map(
                              (link, linkI) =>
                                link && (
                                  <Button
                                    key={linkI}
                                    href={link.url}
                                    type={"link"}
                                    size={"large"}
                                    className="px-0 pb-0"
                                    target="_blank"
                                  >
                                    {link.text}
                                  </Button>
                                )
                            )}
                          </Col>
                        )}
                      </Row>
                    </Col>
                  ))}

                  <Col span={24}>
                    <Row gutter={[10, 20]} justify={"end"}>
                      <Col xxl={6} xl={6} lg={6} md={10} sm={24} xs={24}>
                        <Button
                          type="primary"
                          size="large"
                          icon={<ReloadOutlined />}
                          block
                          onClick={() =>
                            navigate(`/explore/${params.type}`, {
                              replace: true,
                            })
                          }
                        >
                          Search for another country
                        </Button>
                      </Col>
                      <Col xxl={4} xl={4} lg={4} md={6} sm={24} xs={24}>
                        <Button
                          icon={<ReloadOutlined />}
                          size="large"
                          block
                          onClick={() => navigate("/", { replace: true })}
                        >
                          New search
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
    </Row>
  );
};
