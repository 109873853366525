import { useEffect, useState } from "react";
import { Col, Image, Row, Typography } from "antd";
import { BlogsTypes } from "../../types/enums";
import { useNavigate, useParams } from "react-router-dom";
import { IBlog } from "../../types";
import { blogsData } from "../../data";
import { CheckOutlined } from "@ant-design/icons";
import {
  replaceHyphenToSpaceAndCapitalize,
  replaceSpaceToHyphenAndLowerCase,
} from "../../utils/helpers";

// Components
import { IRImage } from "../../components/shared/ir-image/ir-image";

// Images
import EmptyBlogs from "../../assets/empty-blogs.svg";

import "./blogs.scss";

const { Text } = Typography;

export const Blogs = () => {
  const [blogs, setBlogs] = useState<IBlog[]>([]);

  const [selectedBlogType, setSelectedBlogType] = useState<string>("");

  const { blogType } = useParams();

  const navigate = useNavigate();

  const filterBlogsWithType = (type: string) => {
    return blogsData.filter(
      (blog) =>
        replaceSpaceToHyphenAndLowerCase(blog.type) ===
        replaceSpaceToHyphenAndLowerCase(type)
    );
  };

  useEffect(() => {
    setSelectedBlogType(
      (replaceSpaceToHyphenAndLowerCase(blogType) as BlogsTypes) ||
        replaceSpaceToHyphenAndLowerCase(BlogsTypes.AccommodationReviews)
    );
  }, []);

  useEffect(() => {
    if (selectedBlogType) {
      setBlogs(filterBlogsWithType(selectedBlogType));
    }
  }, [selectedBlogType]);

  const onBlogTypeSelect = (type: BlogsTypes) => {
    const blogTypeName = replaceSpaceToHyphenAndLowerCase(type);
    setSelectedBlogType(blogTypeName);
    navigate(`/blogs/${blogTypeName}`, { replace: true });
  };

  const onBlogClick = (blogName: string) => {
    navigate(`/blog/${replaceSpaceToHyphenAndLowerCase(blogName)}`);
  };

  return (
    <Row className="blogs-container" justify={"space-evenly"} align={"middle"}>
      {/* Top content */}
      <Col span={24} className="heading-col mb-5">
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            <Text className="font-40 font-bold title my-2">
              {replaceHyphenToSpaceAndCapitalize(selectedBlogType)}
            </Text>
          </Col>
        </Row>
      </Col>

      <Col xxl={19} xl={19} lg={19} md={21} sm={21} xs={21}>
        <Row
          className="content"
          gutter={[{ xxl: 80, xl: 80, lg: 80, md: 80, sm: 0, xs: 0 }, 80]}
        >
          {/* Blogs */}
          <Col
            xxl={18}
            xl={18}
            lg={18}
            md={24}
            sm={24}
            xs={24}
            className="blogs"
          >
            <Row gutter={[40, 40]}>
              {!blogs.length && (
                <Col span={24}>
                  <Row justify={"center"} className="text-center">
                    <Col span={24}>
                      <Image src={EmptyBlogs} preview={false} width={220} />
                    </Col>
                    <Col span={"auto"}>
                      <Text className="font-24 black-1">
                        Stay tune for more blogs for
                        <span className="primary">
                          {" "}
                          {replaceHyphenToSpaceAndCapitalize(selectedBlogType)}
                        </span>
                      </Text>
                    </Col>
                  </Row>
                </Col>
              )}
              {blogs.map((blog, i) => (
                <Col
                  span={24}
                  key={i}
                  className="cursor-pointer"
                  onClick={() => onBlogClick(blog.title)}
                >
                  <Row gutter={[20, 20]} justify={"space-between"}>
                    <Col
                      xxl={10}
                      xl={10}
                      lg={10}
                      md={24}
                      sm={24}
                      xs={24}
                      className="blog-image"
                    >
                      <IRImage src={blog.image} />
                    </Col>

                    <Col
                      xxl={14}
                      xl={14}
                      lg={14}
                      md={24}
                      sm={24}
                      xs={24}
                      className="blog-details"
                    >
                      <Row gutter={[0, 10]}>
                        <Col span={24} className="hover-primary">
                          <Text className="font-22 black-1 font-bold">
                            {blog.title}
                          </Text>
                        </Col>

                        <Col span={24}>
                          <Text className="font-16 grey-5 paragraph-text">
                            {blog.paragraphs[0].text}
                          </Text>
                        </Col>

                        <Col span={24} className="date-and-reading-time">
                          <Text className="grey-7 ">
                            <span>{blog.date.toDateString()}</span>
                            <hr className="hr-line" />
                            <span>{blog.readingMins} Mins</span>
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>

          {/* Categories */}
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={24}
            sm={24}
            xs={24}
            className="blog-type-categories"
          >
            <Row>
              <Col span={24} className="font-22 black-1 font-bold mb-4">
                Categories
              </Col>

              {Object.values(BlogsTypes).map((value) => (
                <Col
                  span={24}
                  className="blog-type-item py-5 hover-primary"
                  key={value}
                  onClick={() => onBlogTypeSelect(value)}
                >
                  <Row justify={"space-between"}>
                    <Col span={"auto"}>
                      <Text className="grey-5 font-16">{value}</Text>
                    </Col>

                    {selectedBlogType ===
                      replaceSpaceToHyphenAndLowerCase(value) && (
                      <Col span={"auto"}>
                        <CheckOutlined
                          style={{ fontSize: 22, color: "#67ab67" }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
