import { addDoc, collection } from "firebase/firestore";
import { IContactFormData } from "../pages/contact-us/contact-us";
import { firestore } from "../firebase/firebase";

export const submitContactUsForm = (payload: IContactFormData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const document = await addDoc(collection(firestore, "contact-us-requests"), payload);
      resolve(document);
    } catch (err) {
      return reject(err);
    }
  });
};