export function replaceSpaceToHyphenAndLowerCase(name?: string) {
  if (name) {
    return name.replace(/\s+/g, "-").toLowerCase();
  }
  return "";
}

export function replaceHyphenToSpaceAndCapitalize(name?: string) {
  if (name) {
    return capitalizeString(name.replaceAll("-", " "));
  }
  return "";
}

export function capitalizeString(string: string) {
  const words = string.split(" ");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}
