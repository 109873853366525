import SVG from "react-inlinesvg";

// Svg Icons
import LocationOutlinedSvg from "../../../assets/icons/location-pin.svg";
import PhoneOutlinedSvg from "../../../assets/icons/phone.svg";
import MailOutlinedSvg from "../../../assets/icons/mail.svg";

export enum SvgIcons {
  LocationOutlined = "LocationOutlined",
  PhoneOutlinedSvg = "PhoneOutlinedSvg",
  MailOutlinedSvg = "MailOutlinedSvg",
}

export const IconToSvgMap = {
  [SvgIcons.LocationOutlined]: LocationOutlinedSvg,
  [SvgIcons.PhoneOutlinedSvg]: PhoneOutlinedSvg,
  [SvgIcons.MailOutlinedSvg]: MailOutlinedSvg,
};

export interface SvgIconProps {
  className?: string;
  icon: SvgIcons;
}

export function SvgIcon({ className = "", icon }: SvgIconProps) {
  const clsProps = `st-svg-icon ${className}`;
  return <SVG className={`${clsProps}`} src={IconToSvgMap[icon]} />;
}
