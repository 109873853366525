import {
  Button,
  Col,
  Form,
  Input,
  List,
  Row,
  Typography,
  notification,
} from "antd";
import { SvgIcon, SvgIcons } from "../../components/shared/svg-icon/svg-icon";
import { Rule } from "antd/es/form";
import { useState } from "react";
import { submitContactUsForm } from "../../apis/contact-us";

// Images
import CountryImage from "../../assets/contact-us-img.jpg";

import "./contact-us.scss";
import { AppChannels } from "../../components/app-channels/app-channels";
import { IRImage } from "../../components/shared/ir-image/ir-image";

const { Title, Text } = Typography;
const { TextArea } = Input;

interface IContactFormValidators {
  name: Rule[];
  message: Rule[];
  email: Rule[];
}

export interface IContactFormData {
  name: string;
  email: string;
  message: string;
  subject?: string;
}

const contactFormInitialValues: IContactFormData = {
  email: "",
  message: "",
  name: "",
  subject: "",
};

const formValidators: IContactFormValidators = {
  email: [
    {
      required: true,
      message: "Please enter your email",
    },
    {
      type: "email",
      message: "Please enter a valid email address",
    },
  ],
  name: [
    {
      required: true,
      message: "Please enter your name",
    },
  ],
  message: [
    {
      required: true,
      message: "Please enter your message",
    },
  ],
};

export const ContactUs = () => {
  const [form] = Form.useForm<IContactFormData>();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const contactDetails = [
    {
      icon: <SvgIcon icon={SvgIcons.LocationOutlined} />,
      label: "Immigration routes",
    },
    {
      icon: <SvgIcon icon={SvgIcons.MailOutlinedSvg} />,
      label: "immigrationroutes@gmail.com",
      path: "immigrationroutes@gmail.com",
    },
  ];

  const onFormValidateSuccess = async (values: IContactFormData) => {
    try {
      setIsSubmittingForm(true);
      await submitContactUsForm(values);
      form.resetFields();
      notification.success({
        message: `Thank you ${values.name} for requesting to contact`,
        description: "we'll get back to you soon",
        type: "success",
        placement: "top",
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmittingForm(false);
    }
  };

  return (
    <Row
      className="contact-us-container"
      justify={"space-between"}
      gutter={[
        { xxl: 100, xl: 100, lg: 100, md: 50, sm: 50, xs: 50 },
        { sm: 50, xs: 50 },
      ]}
      align={"middle"}
    >
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Row>
          <Col span={24}>
            <Title>Contact with us</Title>
          </Col>

          <Col span={24}>
            <Text className="font-16">
              We'd love to hear from you! Whether you have a question, comment,
              or just want to say hello, please don't hesitate to get in touch
              with us.
            </Text>
          </Col>

          <Col span={24} className="mt-10">
            <List
              itemLayout="horizontal"
              dataSource={contactDetails}
              className="contact-details"
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    avatar={item.icon}
                    title={
                      item.path ? (
                        <a href={item.path}>{item.label}</a>
                      ) : (
                        item.label
                      )
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Col>

      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Row>
          <Col span={24} className="image-col">
            <IRImage src={CountryImage} className="display-image" />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row
          gutter={[
            { xxl: 100, xl: 100, lg: 100, md: 50, sm: 50, xs: 50 },
            { sm: 50, xs: 50 },
          ]}
          align={"middle"}
        >
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form
              form={form}
              name="ContactForm"
              initialValues={contactFormInitialValues}
              layout="vertical"
              requiredMark={false}
              className="contact-form"
              onFinish={onFormValidateSuccess}
              disabled={isSubmittingForm}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="name"
                    rules={formValidators.name}
                    label="Name"
                  >
                    <Input type="text" size="large" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Row gutter={[20, 0]}>
                    <Col span={12}>
                      <Form.Item
                        name="email"
                        rules={formValidators.email}
                        label="Email"
                      >
                        <Input type="email" size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="subject" label="Subject">
                        <Input type="text" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="message"
                    rules={formValidators.message}
                    label="Message"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Button
                    size="large"
                    className="submit-btn mt-2"
                    htmlType="submit"
                    disabled={isSubmittingForm}
                    loading={isSubmittingForm}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Row>
              <Col span={24}>
                <Text className="font-40">Let's Connect</Text>
              </Col>

              <Col span={24}>
                <Text className="font-16">
                  We would love to hear from you and answer any questions you
                  may have.Our team is always ready to assist you in any way we
                  can.
                </Text>
              </Col>

              <Col span={24} className="mt-5">
                <Text className="font-18">Follow us</Text>
              </Col>

              <Col span={24} className="mt-2">
                <AppChannels />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
