import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { initializeLibraries } from "../utils/libs-initializer";

export interface ProvidersProps {
  children: React.ReactNode;
}

export function AppProviders({ children }: ProvidersProps) {

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    initializeLibraries();
    setIsReady(true);
  }, []);

  if (!isReady) {
    return null;
  }

  return <BrowserRouter>{children}</BrowserRouter>;
}
