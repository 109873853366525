import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { ITermsOfUse } from "../types";

export const getTermsOfUse = () => {
  return new Promise<ITermsOfUse[]>(async (resolve, reject) => {
    try {
      const collectionCol = collection(firestore, "terms-of-use");
      const documents = await getDocs(collectionCol);
      const data = documents.docs.map((doc) => doc.data());
      return resolve(data[0].data as ITermsOfUse[]);
    } catch (err) {
      return reject(err);
    }
  });
};
