export enum EnvironmentsTypes {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

export enum ImmigrationTypes {
  Immigration = "immigration",
  Visit = "visit",
  Business = "business",
  Student = "student",
  Work = "work",
}
export enum BlogsTypes {
  DestinationGuides = "Destination Guides",
  TravelTipsAndAdvice = "Travel Tips & Advice",
  Itineraries = "Itineraries",
  AccommodationReviews = "Accommodation Reviews",
  FoodAndDrink = "Food & Drink",
  TravelInspiration = "Travel Inspiration",
  AdventureTravel = "Adventure Travel",
  CulturalExperiences = "Cultural Experiences",
  FamilyTravel = "Family Travel",
  SoloTravel = "Solo Travel",
  CouplesTravel = "Couples Travel",
  TravelGearAndProducts = "Travel Gear & Products",
  SustainableTravel = "Sustainable Travel",
  TravelDealsAndNews = "Travel Deals & News",
  Transport = "Transport",
}
