import { Col, Row, Typography } from "antd";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { AppChannels } from "../app-channels/app-channels";
import { IRImage } from "../shared/ir-image/ir-image";
import { BlogsTypes } from "../../types/enums";

import "./app-footer.scss";
import { replaceSpaceToHyphenAndLowerCase } from "../../utils/helpers";

const { Text } = Typography;

export const AppFooter = () => {
  const categories = [
    {
      label: "Home",
      link: "",
    },
    // {
    //   label: "News",
    //   link: "/news",
    // },
    {
      label: "Read our blogs",
      link: `/blogs/${replaceSpaceToHyphenAndLowerCase(
        BlogsTypes.AccommodationReviews
      )}`,
    },
    {
      label: "Travel Advisory",
      link: "/travel-advisory",
    },
  ];

  const pages = [
    {
      label: "About us",
      link: "/about-us",
    },
    {
      label: "Contact",
      link: "/contact-us",
    },
    {
      label: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      label: "Advertise",
      link: "/advertise",
    },
    {
      label: "Terms of Use",
      link: "/terms-of-use",
    },
  ];

  return (
    <Row className="footer" justify={"center"} align={"middle"}>
      <Col span={22} className="px-5 py-10">
        <Row align={"top"} gutter={[20, 40]}>
          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <Row className="company-dec" gutter={[0, 20]}>
              <Col span={24}>
                <NavLink to="/" className="logo">
                  <IRImage src={Logo} />
                  <span className="px-2 font-20 font-bold">
                    Immigration Routes
                  </span>
                </NavLink>
              </Col>
              <Col span={24}>
                <Text className="description">
                  Navigate your Immigration Journey with Us!
                </Text>
              </Col>
              <Col>
                <AppChannels />
              </Col>
            </Row>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <Row gutter={[0, 20]}>
              {categories.map((category) => (
                <Col span={24} key={category.label}>
                  <NavLink to={category.link} className="footer-links font-16">
                    {category.label}
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Col>

          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <Row gutter={[0, 20]}>
              {pages.map((page) => (
                <Col span={24} key={page.label}>
                  <NavLink to={page.link} className="footer-links font-16">
                    {page.label}
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="p-4 copyright-col">
        <Text className="font-12">
          Copyright © {new Date().getFullYear()} Immigration Routes. All Rights
          Reserved. (* We do not provide legal advice)
        </Text>
      </Col>
    </Row>
  );
};
