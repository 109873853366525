import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { ICountryImmigrationAndVisaInfo } from "../types";

export const getCountriesVisaImmigrationInfo = () => {
  return new Promise<ICountryImmigrationAndVisaInfo[]>(
    async (resolve, reject) => {
      try {
        const collectionCol = collection(
          firestore,
          "countries-immigration-visa-information"
        );
        const documents = await getDocs(collectionCol);
        const data = documents.docs.map((doc) => doc.data());
        return resolve(data[0].data as ICountryImmigrationAndVisaInfo[]);
      } catch (err) {
        return reject(err);
      }
    }
  );
};
