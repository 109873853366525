import { Col, Row, Typography } from "antd";
import { ExploreVisaOptions } from "../../components/re-useable/explore-visa-options/explore-visa-options";
import { IRImage } from "../../components/shared/ir-image/ir-image";
import { useIsMobile } from "../../helpers/hooks/use-is-mobile/use-is-mobile";

import "./home.scss";

// Components
import { Blogs } from "../../components/re-useable/blogs/blogs";

const { Title, Text } = Typography;

export const Home = () => {
  const isMobile = useIsMobile();

  return (
    <Row
      className="home-container mb-5"
      justify={"space-evenly"}
      gutter={[
        { xxl: 100, xl: 100, lg: 100, md: 50, sm: 50, xs: 50 },
        { sm: 50, xs: 50 },
      ]}
      align={"middle"}
    >
      <Col xxl={21} xl={21} lg={21} md={22} sm={24} xs={24}>
        <Row
          gutter={[50, 50]}
          justify={"space-between"}
          align={"middle"}
          className="mt-10 top-row"
        >
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Row>
              <Col span={24}>
                <Title className="title">
                  Your Reliable Source for a Brighter Future.
                </Title>
              </Col>

              <Col span={24}>
                <Text>
                  Our platform provides you with detailed insights into the
                  specific eligibility criteria, documentation, and procedures
                  needed to plan your successful immigration.
                </Text>
              </Col>
            </Row>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="image">
            <IRImage
              src={
                "https://firebasestorage.googleapis.com/v0/b/immigration-routes.appspot.com/o/immigration-main-2.jpg?alt=media&token=86a40d0d-e6d1-4539-982d-603374291c2e"
              }
              wrapperClassName="preview-image"
              height={isMobile ? 290 : 390}
              width={600}
            />
          </Col>
        </Row>
      </Col>

      <Col xxl={21} xl={21} lg={21} md={22} sm={24} xs={24}>
        <ExploreVisaOptions />
      </Col>

      <Col xxl={21} xl={21} lg={21} md={22} sm={24} xs={24}>
        <Blogs />
      </Col>
    </Row>
  );
};
