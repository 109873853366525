import { Col, Row, Typography } from "antd";

import "./advertise.scss";

const { Text } = Typography;
export const Advertise = () => {
  return (
    // <div style={{ height: "100%", textAlign: "center" }} className="py-10">
    //   <span className="font-32">Advertise page</span>
    // </div>

    <Row
      className="about-us-container mb-5"
      justify={"space-evenly"}
      gutter={[
        { xxl: 100, xl: 100, lg: 100, md: 50, sm: 50, xs: 50 },
        { sm: 50, xs: 50 },
      ]}
      align={"middle"}
    >
      <Col span={24} className="heading-col  mb-5">
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            <Text className="font-40 font-bold title my-2">Advertise</Text>
          </Col>
          <Col span={24} className="content text-center">
            <Text>
              Let's work together for a successful journey.
            </Text>
          </Col>
        </Row>
      </Col>

      <Row justify={"space-evenly"} className="content mb-5">
        <Col className="content" xl={10} lg={12} md={12} sm={24} xs={24}>
          <Row>
            <Col span={24}>
              <Text className="font-16">
                Unlock new opportunities and expand the reach of your immigration law practice by advertising with us on our website. As a leading platform catering to a diverse audience seeking immigration-related information and services, your business can benefit immensely from targeted visibility. Our website provides a prime space for immigration attorneys to connect with potential clients actively seeking legal expertise and guidance. By showcasing your services prominently, you position your practice in front of individuals navigating the complexities of immigration processes.
                Advertising with us not only enhances your online presence but also establishes your firm as a trusted authority in immigration law. Capture the attention of a focused demographic interested in visa applications, legal consultations, and immigration solutions. Our platform offers a unique opportunity to engage with a global audience, including individuals, families, and businesses seeking reliable legal representation.
                Join us in fostering a community where immigration attorneys can thrive, and clients can find the assistance they need. Take the next step to grow your business and make a lasting impact in the field of immigration law by choosing to advertise with us. Contact us today to explore the tailored advertising options available for your firm and elevate your visibility in the immigration legal landscape.
              </Text>
            </Col>
          </Row>
        </Col>

      </Row>
    </Row>
  );
};
