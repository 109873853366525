import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Skeleton, Typography } from "antd";
import { IVisaOptions } from "./types";
import { IRImage } from "../../shared/ir-image/ir-image";
import { getVisaExploreOptions } from "../../../apis/visa-options";

import "./explore-visa-options.scss";

const { Text } = Typography;
const { Button: LoadingButton, Input: LoadingInput } = Skeleton;


export const ExploreVisaOptions = () => {

  const navigate = useNavigate();

  const [visaOptions, setVisaOptions] = useState<IVisaOptions | undefined>() ;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getVisaOptions();
  },[]);

  const getVisaOptions = async () => {
    try {
      setIsLoading(true);
      const data: IVisaOptions = await getVisaExploreOptions();
      setVisaOptions(data);
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectOption = (path: string) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <Row justify={"space-between"} className="explore-visa-options my-10">
      {/* Placeholder cards */}
      {isLoading && (
        <Col span={24}>
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <LoadingInput
                active
                style={{ height: 40, borderRadius: 8 }}
                size={"large"}
              />
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <LoadingButton
                active
                block
                style={{ height: 400 }}
                className="skeleton-borders"
              />
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row className="options-row" gutter={[20, 20]}>
                {[1, 2, 3, 4].map((i) => (
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    className="option cursor-pointer hover-red"
                    key={i}
                  >
                    <LoadingButton
                      active
                      block
                      style={{ height: 220 }}
                      className="skeleton-borders"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      )}

      {/* Visa Options */}
      {!isLoading && !!visaOptions && (
        <Col span={24}>
          <Row gutter={[30, 15]}>
            <Col span={24}>
              <Text className="mb-0 font-bold visa-option-title">{visaOptions.title}</Text>
            </Col>

            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row gutter={[50, 20]} className="main-option-row cursor-pointer">
                <Col span={24}>
                  <Row
                    gutter={[50, 20]}
                    className="hover-red"
                    onClick={() => onSelectOption(visaOptions.mainOption.path)}
                  >
                    <Col span={24}>
                      <IRImage
                        src={visaOptions.mainOption.image}
                        wrapperClassName="image"
                        height={400}
                      />
                    </Col>
                    <Col span={24}>
                      <Text className="font-26">{visaOptions.mainOption.title}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Row
                className="options-row"
                gutter={[20, 20]}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                {visaOptions.options.map((option, index) => (
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    className="option cursor-pointer hover-red"
                    key={index}
                    onClick={() => onSelectOption(option.path)}
                    style={{ height: "100%" }}
                  >
                    <Row gutter={[20, 10]}>
                      <Col span={24}>
                        <IRImage
                          src={option.image}
                          className="image"
                          height={220}
                        />
                      </Col>
                      <Col span={24}>
                        <Text className="font-20 title">{option.title}</Text>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
